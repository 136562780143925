import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = "https://backend-kostum.zoom-app.kz/api/";
const API_URL = "https://newapi.kostyum.kz/api/";
// const API_URL = "http://localhost:8000/api/";

class AuthService {
  // USER
  getUser() {
    return axios.get(API_URL + "user/get/", {
      headers: authHeader(),
    });
  }
  addUser(item) {
    return axios.post(API_URL + "user/user_admin/", item,{
      headers: authHeader(),
    });
  }
  getUserAll() {
    return axios.get(API_URL + "user/user/get_all/", {
      headers: authHeader(),
    });
  }
  getUserId(id) {
    return axios.get(API_URL + "user/" + id + "/get/", {
      headers: authHeader(),
    });
  }
  getUserSerch(item) {
    return axios.get(API_URL + "user/user/get_all/?search=" + item, {
      headers: authHeader(),
    });
  }
  patchUser(item) {
    return axios.patch(API_URL + "user/user_admin/" + item.id, item, {
      headers: authHeader(),
    });
  }
  deliteUser(id) {
    console.log(id);
    return axios.delete(API_URL + "user/user_admin/" + id, {
      headers: authHeader(),
    });
  }
  importUser(item) {
    return axios.post(API_URL + "user/upload_excel/", item,{
      headers: authHeader(),
    });
  }

  exportUser() {
    return axios.get(API_URL + "user/export_excel/",{
      headers: authHeader(),
    });
  }
  // PRODUCT
  addProductsGrup(item) {
    return axios.post(API_URL + "product/sku_group/create/", item, {
      headers: authHeader(),
    });
  }
  getProductsGrupAll() {
    return axios.get(API_URL + "product/sku_group/", {
      headers: authHeader(),
    });
  }
  getProductsGrupsSerch(serch) {
    return axios.get(API_URL + "product/sku_group/?search=" + serch, {
      headers: authHeader(),
    });
  }
  getProductsGrupId(id) {
    return axios.get(API_URL + "product/sku_group/" + id + "/", {
      headers: authHeader(),
    });
  }
  putProductsGrup(id, item) {
    return axios.put(API_URL + "product/sku_group/" + id + "/update/", item, {
      headers: authHeader(),
    });
  }
  getProductsFilter(id) {
    return axios.get(
      API_URL + "category/category_filters/get/?category_id=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getProductsAll() {
    return axios.get(API_URL + "product/product/", {
      headers: authHeader(),
    });
  }
  // getProductsAll() {
  //   return axios.get(API_URL + 'product/product/?limit=10000', {
  //     headers: authHeader(),
  //   });
  // }
  getProductsPage(page) {
    return axios.get(
      API_URL + "product/product/?limit=100&offset=" + page + "00",
      {
        headers: authHeader(),
      }
    );
  }
  getProductsSerch(item) {
    return axios.get(API_URL + "product/product/?search=" + item, {
      headers: authHeader(),
    });
  }
  getProductsGrupSerch(item) {
    return axios.get(API_URL + "product/sku_group/?search=" + item, {
      headers: authHeader(),
    });
  }
  getProductsID(id) {
    return axios.get(API_URL + "product/product/" + id + "/", {
      headers: authHeader(),
    });
  }
  putProducts(id, item) {
    return axios.put(API_URL + "product/product/" + id + "/", item, {
      headers: authHeader(),
    });
  }
  postProductsGrupAttachedCategory(sku, category) {
    return axios.post(
      API_URL + "product/attached_category/",
      { sku: sku, category: category },
      {
        headers: authHeader(),
      }
    );
  }
  putProductsGrupAttachedCategory(id, sku, category) {
    return axios.put(
      API_URL + "category/attached_category/" + id + "/",
      { sku: sku, category: category },
      {
        headers: authHeader(),
      }
    );
  }
  getExeleName() {
    return axios.get(API_URL + "user/get_current_excel_name", {
      headers: authHeader(),
    });
  }
  getExele() {
    return axios.get(API_URL + "product/export/", {
      headers: authHeader(),
    });
  }
  importExele(item) {
    return axios.post(API_URL + "product/import/", item, {
      headers: authHeader(),
    });
  }
  editPrice(item) {
    return axios.post(API_URL + "bonus/change_price/", item, {
      headers: authHeader(),
    });
  }
  deliteProductsGrup(id) {
    console.log(id);
    return axios.delete(API_URL + "product/sku_group/" + id + "/delete/", {
      headers: authHeader(),
    });
  }
  // CATEGORY

  getCategoryFilters(id) {
    return axios.get(
      API_URL + "category/get_category_visible_filters/" + id + "/",
      {
        headers: authHeader(),
      }
    );
  }
  postCategoryFilters(id, visible) {
    return axios.post(
      API_URL + "category/update_category_visible_filters/" + id + "/",
      visible,
      {
        headers: authHeader(),
      }
    );
  }
  getCategorys() {
    return axios.get(API_URL + "category/category/", {
      headers: authHeader(),
    });
  }
  getCategoryId(id) {
    return axios.get(API_URL + "category/category/" + id + "/get/", {
      headers: authHeader(),
    });
  }
  postCategory(item) {
    return axios.post(API_URL + "category/category/create/", item, {
      headers: authHeader(),
    });
  }
  putCategory(id, item) {
    return axios.patch(API_URL + "category/category/" + id + "/update/", item, {
      headers: authHeader(),
    });
  }
  delCategory(id) {
    return axios.delete(API_URL + "category/category/" + id + "/delete/", {
      headers: authHeader(),
    });
  }
  // Filter
  // Характеристики

  getFilter() {
    return axios.get(API_URL + "category/characteristic/", {
      headers: authHeader(),
    });
  }
  getFilterId(id) {
    return axios.get(API_URL + "category/characteristic/" + id + "/get/", {
      headers: authHeader(),
    });
  }
  addFilter(item) {
    return axios.post(API_URL + "category/characteristic/create/", item, {
      headers: authHeader(),
    });
  }
  putFilter(id, item) {
    return axios.put(
      API_URL + "category/characteristic/" + id + "/update/",
      item,
      {
        headers: authHeader(),
      }
    );
  }

  delFilter(id) {
    return axios.delete(
      API_URL + "category/characteristic/" + id + "/delete/",
      {
        headers: authHeader(),
      }
    );
  }

  addValue() {
    return axios.post(
      API_URL + "category/characteristic_value/create/",
      { value: 0 },
      {
        headers: authHeader(),
      }
    );
  }
  updateValue(id, item) {
    return axios.put(
      API_URL + "category/characteristic_value/" + id + "/update/",
      { value: item },
      {
        headers: authHeader(),
      }
    );
  }

  // Цвет
  addColor(item) {
    return axios.post(API_URL + "product/color/", item, {
      headers: authHeader(),
    });
  }
  getColor() {
    return axios.get(API_URL + "product/color/?limit=9999", {
      headers: authHeader(),
    });
  }
  getColorId(id) {
    return axios.get(API_URL + "product/color/" + id + "/", {
      headers: authHeader(),
    });
  }
  putColor(id, item) {
    return axios.put(API_URL + "product/color/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delColor(id) {
    return axios.delete(API_URL + "product/color/" + id + "/", {
      headers: authHeader(),
    });
  }

  // Размер
  addSize(item) {
    return axios.post(API_URL + "product/size/",{ size_name: item },{
        headers: authHeader(),
      }
    );
  }
  getSize() {
    return axios.get(API_URL + "product/size/?limit=9999", {
      headers: authHeader(),
    });
  }
  getSizeId(id) {
    return axios.get(API_URL + "product/size/" + id + "/", {
      headers: authHeader(),
    });
  }
  putSize(id, item) {
    return axios.put(API_URL + "product/size/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delSize(id) {
    return axios.delete(API_URL + "product/size/" + id + "/", {
      headers: authHeader(),
    });
  }
  // Cites
  addCites(item) {
    return axios.post(API_URL + "delivery/delivery_city/", {name: item},{
        headers: authHeader(),
      }
    );
  }
  getCites() {
    return axios.get(API_URL + "delivery/delivery_city/", {
      headers: authHeader(),
    });
  }
  getCitesId(id) {
    return axios.get(API_URL + "delivery/delivery_city/" + id + "/", {
      headers: authHeader(),
    });
  }
  putCites(id, item) {
    return axios.put(API_URL + "delivery/delivery_city/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delCites(id) {
    return axios.delete(API_URL + "delivery/delivery_city/" + id + "/", {
      headers: authHeader(),
    });
  }

  // Бранды
  addBrand(item) {
    return axios.post(
      API_URL + "product/brand/",
      { name: item },
      {
        headers: authHeader(),
      }
    );
  }
  getBrand() {
    return axios.get(API_URL + "product/brand/?limit=9999", {
      headers: authHeader(),
    });
  }
  getBrandId(id) {
    return axios.get(API_URL + "product/brand/" + id + "/", {
      headers: authHeader(),
    });
  }
  putBrand(id, item) {
    return axios.put(API_URL + "product/brand/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delBrand(id) {
    return axios.delete(API_URL + "product/brand/" + id + "/", {
      headers: authHeader(),
    });
  }

  // Магазины
  addShop(item) {
    return axios.post(API_URL + "product/shop/", item, {
      headers: authHeader(),
    });
  }
  getShop() {
    return axios.get(API_URL + "product/shop/", {
      headers: authHeader(),
    });
  }
  getShopId(id) {
    return axios.get(API_URL + "product/shop/" + id + "/", {
      headers: authHeader(),
    });
  }
  putShop(id, item) {
    return axios.put(API_URL + "product/shop/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delShop(id) {
    return axios.delete(API_URL + "product/shop/" + id + "/", {
      headers: authHeader(),
    });
  }

  // Варианты доставки
  addDelivery(item) {
    return axios.post(API_URL + "delivery/delivery/", item, {
      headers: authHeader(),
    });
  }
  getDelivery() {
    return axios.get(API_URL + "delivery/delivery/", {
      headers: authHeader(),
    });
  }
  getDeliveryID(id) {
    return axios.get(API_URL + "delivery/delivery/" + id + "/", {
      headers: authHeader(),
    });
  }
  putDelivery(id, item) {
    return axios.put(API_URL + "delivery/delivery/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delDelivery(id) {
    return axios.delete(API_URL + "delivery/delivery/" + id + "/", {
      headers: authHeader(),
    });
  }

  // HOME
  // Baner
  addBaner(item) {
    return axios.post(API_URL + "main/main_img/", item, {
      headers: authHeader(),
    });
  }
  getBaner() {
    return axios.get(API_URL + "main/main_img/", {
      headers: authHeader(),
    });
  }
  getBanerID(id) {
    return axios.get(API_URL + "main/main_img/" + id + "/", {
      headers: authHeader(),
    });
  }
  putBaner(id, item) {
    return axios.put(API_URL + "main/main_img/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delBaner(id) {
    return axios.delete(API_URL + "main/main_img/" + id + "/", {
      headers: authHeader(),
    });
  }

  // SecondBaner
  addSecondBaner(item) {
    return axios.post(API_URL + "main/main_best_category_img/", item, {
      headers: authHeader(),
    });
  }
  getSecondBaner() {
    return axios.get(API_URL + "main/main_best_category_img/", {
      headers: authHeader(),
    });
  }
  getSecondBanerID(id) {
    return axios.get(API_URL + "main/main_best_category_img/" + id + "/", {
      headers: authHeader(),
    });
  }
  putSecondBaner(id, item) {
    return axios.patch(
      API_URL + "main/main_best_category_img/" + id + "/",
      item,
      {
        headers: authHeader(),
      }
    );
  }

  delSecondBaner(id) {
    return axios.delete(API_URL + "main/main_best_category_img/" + id + "/", {
      headers: authHeader(),
    });
  }
  // Lists
  addLists(item) {
    return axios.post(API_URL + "main/best_offer_item/create/", item, {
      headers: authHeader(),
    });
  }
  getLists() {
    return axios.get(API_URL + "main/best_offer_item/", {
      headers: authHeader(),
    });
  }
  getListsID(id) {
    return axios.get(API_URL + "main/best_offer_item/" + id + "/get/", {
      headers: authHeader(),
    });
  }
  putLists(id, item) {
    return axios.put(
      API_URL + "main/best_offer_item/" + id + "/update/",
      item,
      {
        headers: authHeader(),
      }
    );
  }

  delLists(id) {
    return axios.delete(API_URL + "main/best_offer_item/" + id + "/delete/", {
      headers: authHeader(),
    });
  }

  // HomeBlock
  addHomeBlock(item) {
    return axios.post(API_URL + "main/best_offer/create/", item, {
      headers: authHeader(),
    });
  }
  getHomeBlock() {
    return axios.get(API_URL + "main/best_offer/", {
      headers: authHeader(),
    });
  }
  getHomeBlockID(id) {
    return axios.get(API_URL + "main/best_offer/" + id + "/get/", {
      headers: authHeader(),
    });
  }
  putHomeBlock(id, item) {
    return axios.put(API_URL + "main/best_offer/" + id + "/update/", item, {
      headers: authHeader(),
    });
  }

  delHomeBlock(id) {
    return axios.delete(API_URL + "main/best_offer/" + id + "/delete/", {
      headers: authHeader(),
    });
  }

  // Advantage
  addAdvantage(item) {
    return axios.post(API_URL + "main/advantage/", item, {
      headers: authHeader(),
    });
  }
  getAdvantage() {
    return axios.get(API_URL + "main/advantage/", {
      headers: authHeader(),
    });
  }
  getAdvantageID(id) {
    return axios.get(API_URL + "main/advantage/" + id + "/", {
      headers: authHeader(),
    });
  }
  putAdvantage(id, item) {
    return axios.patch(API_URL + "main/advantage/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delAdvantage(id) {
    return axios.delete(API_URL + "main/advantage/" + id + "/", {
      headers: authHeader(),
    });
  }

  // BLOG
  getBlog() {
    return axios.get(API_URL + "blog/post_list/", {
      headers: authHeader(),
    });
  }
  getBlogId(id) {
    return axios.get(API_URL + "blog/post_list/" + id + "/", {
      headers: authHeader(),
    });
  }
  postBlog(item) {
    return axios.post(API_URL + "blog/post_list/", item, {
      headers: authHeader(),
    });
  }
  putBlog(id, item) {
    return axios.patch(API_URL + "blog/post_list/" + id + "/", item, {
      headers: authHeader(),
    });
  }
  delBlog(id) {
    return axios.delete(API_URL + "blog/post_list/" + id + "/", {
      headers: authHeader(),
    });
  }
  // Альбомы

  addAlbum(item) {
    return axios.post(API_URL + "photo_album/album/", item, {
      headers: authHeader(),
    });
  }

  getAlbumId(id) {
    return axios.get(API_URL + "photo_album/album/" + id + "/", {
      headers: authHeader(),
    });
  }

  putAlbum(id, item) {
    return axios.patch(API_URL + "photo_album/album/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  getAlbum() {
    return axios.get(API_URL + "photo_album/album/", {
      headers: authHeader(),
    });
  }

  delAlbum(id) {
    return axios.delete(API_URL + "photo_album/album/" + id + "/", {
      headers: authHeader(),
    });
  }
  delAlbumImg(id) {
    return axios.delete(API_URL + "photo_album/album_image/" + id + "/", {
      headers: authHeader(),
    });
  }

  //public oferta
  getOferta() {
    return axios.get(API_URL + "other_pages/public_offer/", {
      headers: authHeader(),
    });
  }

  addOferta(data) {
    return axios.post(API_URL + "other_pages/public_offer/", data, {
      headers: authHeader(),
    });
  }

  delOferta(id) {
    return axios.delete(API_URL + "other_pages/public_offer/" + id + "/", {
      headers: authHeader(),
    });
  }

  getOfertaById(id) {
    return axios.get(API_URL + "other_pages/public_offer/" + id + "/", {
      headers: authHeader(),
    });
  }

  editOferta(id, data) {
    return axios.put(API_URL + "other_pages/public_offer/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  //public oferta

  //politics
  getPoltics() {
    return axios.get(API_URL + "other_pages/privacy_policy/", {
      headers: authHeader(),
    });
  }

  addPoltics(data) {
    return axios.post(API_URL + "other_pages/privacy_policy/", data, {
      headers: authHeader(),
    });
  }

  delPoltics(id) {
    return axios.delete(API_URL + "other_pages/privacy_policy/" + id + "/", {
      headers: authHeader(),
    });
  }

  getPolticsById(id) {
    return axios.get(API_URL + "other_pages/privacy_policy/" + id + "/", {
      headers: authHeader(),
    });
  }

  editPoltics(id, data) {
    return axios.put(API_URL + "other_pages/privacy_policy/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  //politics

  //workTime
  getSchedule() {
    return axios.get(API_URL + "other_pages/schedule/", {
      headers: authHeader(),
    });
  }

  addSchedule(data) {
    return axios.post(API_URL + "other_pages/schedule/", data, {
      headers: authHeader(),
    });
  }

  delSchedule(id) {
    return axios.delete(API_URL + "other_pages/schedule/" + id + "/", {
      headers: authHeader(),
    });
  }

  getScheduleById(id) {
    return axios.get(API_URL + "other_pages/schedule/" + id + "/", {
      headers: authHeader(),
    });
  }

  editSchedule(id, data) {
    return axios.put(API_URL + "other_pages/schedule/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  //workTime

  //trends
  getTrends() {
    return axios.get(API_URL + "other_pages/trends/", {
      headers: authHeader(),
    });
  }

  addTrends(data) {
    return axios.post(API_URL + "other_pages/trends/", data, {
      headers: authHeader(),
    });
  }

  delTrends(id) {
    return axios.delete(API_URL + "other_pages/trends/" + id + "/", {
      headers: authHeader(),
    });
  }

  getTrendsById(id) {
    return axios.get(API_URL + "other_pages/trends/" + id + "/", {
      headers: authHeader(),
    });
  }

  editTrends(id, data) {
    return axios.put(API_URL + "other_pages/trends/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  //trends

  //partners
  getPartners() {
    return axios.get(API_URL + "main/partners/", {
      headers: authHeader(),
    });
  }

  addPartners(data) {
    return axios.post(API_URL + "main/partners/", data, {
      headers: authHeader(),
    });
  }

  delPartners(id) {
    return axios.delete(API_URL + "main/partners/" + id + "/", {
      headers: authHeader(),
    });
  }

  getPartnersById(id) {
    return axios.get(API_URL + "main/partners/" + id + "/", {
      headers: authHeader(),
    });
  }

  editPartners(id, data) {
    return axios.put(API_URL + "main/partners/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  //partners

  //about
  getAbout() {
    return axios.get(API_URL + "other_pages/about/", {
      headers: authHeader(),
    });
  }

  addAbout(data) {
    return axios.post(API_URL + "other_pages/about/", data, {
      headers: authHeader(),
    });
  }

  delAbout(id) {
    return axios.delete(API_URL + "other_pages/about/" + id + "/", {
      headers: authHeader(),
    });
  }

  getAboutById(id) {
    return axios.get(API_URL + "other_pages/about/" + id + "/", {
      headers: authHeader(),
    });
  }

  editAbout(id, data) {
    return axios.put(API_URL + "other_pages/about/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  //about

  //phone
  getPhone() {
    return axios.get(API_URL + "main/phone/", {
      headers: authHeader(),
    });
  }

  getPhoneId(id) {
    return axios.get(API_URL + "main/phone/" + id + "/", {
      headers: authHeader(),
    });
  }

  postPhone(item) {
    return axios.post(API_URL + "main/phone/", item, {
      headers: authHeader(),
    });
  }

  putPhone(id, item) {
    return axios.put(API_URL + "main/phone/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delPhone(id) {
    return axios.delete(API_URL + "main/phone/" + id + "/", {
      headers: authHeader(),
    });
  }

  //phone

  //main_information
  getMain() {
    return axios.get(API_URL + "main/main/", {
      headers: authHeader(),
    });
  }

  addMain(item) {
    return axios.post(API_URL + "main/main/", item, {
      headers: authHeader(),
    });
  }

  getMainId(id) {
    return axios.get(API_URL + "main/main/" + id + "/", {
      headers: authHeader(),
    });
  }

  putMain(id, item) {
    return axios.put(API_URL + "main/main/" + id + "/", item, {
      headers: authHeader(),
    });
  }

  delMain(id) {
    return axios.delete(API_URL + "main/main/" + id + "/", {
      headers: authHeader(),
    });
  }
  //main_information
  // Order

  createOrder(data) {
    return axios.post(API_URL + "order/create/", data, {
      headers: authHeader(),
    });
  }

  getOrderSerch(serch) {
    return axios.get(API_URL + "order/get/?search=" + serch, {
      headers: authHeader(),
    });
  }
  getOrder() {
    return axios.get(API_URL + "order/graph/", {
      headers: authHeader(),
    });
  }
  getOrderId(id) {
    return axios.get(API_URL + "order/get/" + id + "/", {
      headers: authHeader(),
    });
  }
  putOrder(item) {
    return axios.put(API_URL + "order/update/" + item.id + "/", item, {
      headers: authHeader(),
    });
  }
  delOrder(id) {
    return axios.delete(API_URL + "order/refund/" + id + "/", {
      headers: authHeader(),
    });
  }
  list(id) {
    return axios.get(API_URL + "order/invoice_get/" + id + "/", {
      headers: authHeader(),
    });
  }
  postKeep(keep) {
    return axios.post(API_URL + "order/admin_comment/", keep, {
      headers: authHeader(),
    });
  }
  // новые другие стр
  getDeliveryTerms() {
    return axios.get(API_URL + "other_pages/delivery_rule/1/", {
      headers: authHeader(),
    });
  }
  putDeliveryTerms(item) {
    return axios.put(API_URL + "other_pages/delivery_rule/1/", item, {
      headers: authHeader(),
    });
  }
  getOrderDelivery() {
    return axios.get(API_URL + "other_pages/order_delivery/1/", {
      headers: authHeader(),
    });
  }
  putOrderDelivery(item) {
    return axios.put(API_URL + "other_pages/order_delivery/1/", item, {
      headers: authHeader(),
    });
  }
  getProductReturn() {
    return axios.get(API_URL + "other_pages/return/1/", {
      headers: authHeader(),
    });
  }
  putProductReturn(item) {
    return axios.put(API_URL + "other_pages/return/1/", item, {
      headers: authHeader(),
    });
  }
  getDiscountSystem() {
    return axios.get(API_URL + "other_pages/action_rule/1/", {
      headers: authHeader(),
    });
  }
  putDiscountSystem(item) {
    return axios.put(API_URL + "other_pages/action_rule/1/", item, {
      headers: authHeader(),
    });
  }
  getGiftCertificate() {
    return axios.get(API_URL + "other_pages/gift_certificate/1/", {
      headers: authHeader(),
    });
  }
  putGiftCertificate(item) {
    return axios.put(API_URL + "other_pages/gift_certificate/1/", item, {
      headers: authHeader(),
    });
  }
  getRent() {
    return axios.get(API_URL + "other_pages/rent/1/", {
      headers: authHeader(),
    });
  }
  putRent(item) {
    return axios.patch(API_URL + "other_pages/rent/1/", item, {
      headers: authHeader(),
    });
  }
  postRentImg() {
    let item = {
      image: null,
      rent: 1,
      product: null,
    };
    return axios.post(API_URL + "other_pages/rent_image/", item, {
      headers: authHeader(),
    });
  }
  putRentImg(id, item) {
    return axios.put(API_URL + "other_pages/rent_image/" + id + "/", item, {
      headers: authHeader(),
    });
  }
  delRentImg(id) {
    return axios.delete(API_URL + "other_pages/rent_image/" + id + "/", {
      headers: authHeader(),
    });
  }

  getQuality() {
    return axios.get(API_URL + "other_pages/quality_assurance/1/", {
      headers: authHeader(),
    });
  }
  putQuality(item) {
    return axios.put(API_URL + "other_pages/quality_assurance/1/", item, {
      headers: authHeader(),
    });
  }
  getAboutsUs() {
    return axios.get(API_URL + "other_pages/about/", {
      headers: authHeader(),
    });
  }
  // ОТЗЫВЫ
  getReview() {
    return axios.get(API_URL + "product/product_review/", {
      headers: authHeader(),
    });
  }
  putReview(item) {
    return axios.put(API_URL + "product/product_review/" + item.id + '/', item, {
      headers: authHeader(),
    });
  }
  delReview(item) {
    return axios.delete(API_URL + "product/product_review/" + item.id + '/', {
      headers: authHeader(),
    });
  }
  getReviewById(id) {
    return axios.get(API_URL + "product/product_review/" + id, {
      headers: authHeader(),
    });
  }
  addAdminOrder(item) {
    return axios.post(API_URL + "order/admin_create/", item, {
      headers: authHeader(),
    });
  }
  getBonusRule() {
    return axios.get(API_URL + "bonus/bonus_rule/get/", {
      headers: authHeader(),
    });
  }
  patchBonusRule(item) {
    return axios.patch(API_URL + "bonus/bonus_rule/patch/1", item, {
      headers: authHeader(),
    });
  }
  getEmail() {
    return axios.get(API_URL + "order/order_responsible/1/", {
      headers: authHeader(),
    });
  }
  putEmail(item) {
    return axios.put(API_URL + "order/order_responsible/1/", item, {
      headers: authHeader(),
    });
  }
  getNextLink(link) {
    return axios.get(link, {
      headers: authHeader(),
    });
  }
}

export default new AuthService();
